import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { db, collection, getDocs } from '../firebaseConfig';
import { CSVLink } from "react-csv";

const AdminDashboard = () => {
  const { user, theme } = useAuth();
  const [userActions, setUserActions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchUserActions();
  }, []);

  const fetchUserActions = async () => {
    setIsLoading(true);
    const actionsCollection = collection(db, "user_actions");
    const actionsSnapshot = await getDocs(actionsCollection);
    const actionsList = actionsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      timestamp: doc.data().timestamp.toDate().toISOString()
    }));
    setUserActions(actionsList);
    setIsLoading(false);
  };

  const csvData = [
    ["ID", "Action", "Data", "Timestamp"],
    ...userActions.map(action => [
      action.id,
      action.action,
      JSON.stringify(action.data),
      action.timestamp
    ])
  ];

  if (!user || user.email !== "dannywchen3@gmail.com") {
    return <div>Access denied. Admin rights required.</div>;
  }

  return (
    <div className={`admin-dashboard ${theme}`}>
      <h1>Admin Dashboard</h1>
      {isLoading ? (
        <p>Loading data...</p>
      ) : (
        <>
          <CSVLink data={csvData} filename={"user_actions.csv"}>
            Download User Actions CSV
          </CSVLink>
          <table>
            <thead>
              <tr>
                <th>Action</th>
                <th>Data</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {userActions.map(action => (
                <tr key={action.id}>
                  <td>{action.action}</td>
                  <td>{JSON.stringify(action.data)}</td>
                  <td>{action.timestamp}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      <style jsx>{`
        .admin-dashboard {
          padding: 20px;
          color: ${theme === 'light' ? '#2d3748' : '#e2e8f0'};
        }

        table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 20px;
        }

        th, td {
          border: 1px solid ${theme === 'light' ? '#e2e8f0' : '#4a5568'};
          padding: 10px;
          text-align: left;
        }

        th {
          background-color: ${theme === 'light' ? '#f7fafc' : '#2d3748'};
        }
      `}</style>
    </div>
  );
};

export default AdminDashboard;