import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const GradientContainer = styled(motion.div)`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const GradientBackground = styled(motion.div)`
  position: absolute;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradientBackground 15s ease infinite;
  transform: translate3d(0, 0, 0);

  @keyframes gradientBackground {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
`;

const ContentContainer = styled.div`
  position: relative;
  z-index: 1;
`;

export const BackgroundGradient = ({ children }) => {
  return (
    <GradientContainer>
      <GradientBackground />
      <ContentContainer>{children}</ContentContainer>
    </GradientContainer>
  );
};