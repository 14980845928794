import { getAnalytics, logEvent } from "firebase/analytics";
import { db, collection, addDoc, serverTimestamp } from "../firebaseConfig";

const analytics = getAnalytics();

export const logUserAction = async (action, data) => {
  // Log to Firebase Analytics
  logEvent(analytics, action, data);

  // Log to Firestore
  try {
    await addDoc(collection(db, "user_actions"), {
      action,
      data,
      timestamp: serverTimestamp(),
    });
  } catch (error) {
    console.error("Error logging user action:", error);
  }
};