import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc, updateDoc, increment, arrayUnion, collection, addDoc, getDocs, serverTimestamp } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDS4Y0StWEkWF-C_QzScOMloKJj4QKptd4",
  authDomain: "masterfy-5aa43.firebaseapp.com",
  projectId: "masterfy-5aa43",
  storageBucket: "masterfy-5aa43.appspot.com",
  messagingSenderId: "572411657380",
  appId: "1:572411657380:web:cf4eb6d69f1629cb423a31",
  measurementId: "G-V79N49W5QZ"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);

export {
  app,
  auth,
  db,
  storage,
  analytics,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  increment,
  arrayUnion,
  collection,
  addDoc,
  getDocs,
  serverTimestamp
};