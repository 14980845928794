import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useAuth } from '../AuthContext';
import axios from 'axios';

const DashboardPractice = () => {
  const { theme } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const generateQuestions = async () => {
      if (location.state?.topic) {
        try {
          const response = await axios.post('https://masterfy.onrender.com/api/chat', {
            message: `Generate 5 multiple-choice questions about ${location.state.topic.title}. Each question should have 4 answer choices and indicate the correct answer. Format the response as a JSON array.`
          });

          const generatedQuestions = JSON.parse(response.data.response);
          setQuestions(generatedQuestions);
        } catch (err) {
          setError('Failed to generate questions. Please try again.');
          console.error('Error:', err);
        } finally {
          setIsLoading(false);
        }
      } else {
        setError('No topic selected. Please go back to the dashboard and select a topic.');
        setIsLoading(false);
      }
    };

    generateQuestions();
  }, [location.state]);

  const handleAnswer = (selectedAnswer) => {
    // Handle answer logic here
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  if (isLoading) {
    return <div className={`dashboard-practice ${theme}`}>Loading questions...</div>;
  }

  if (error) {
    return (
      <div className={`dashboard-practice ${theme}`}>
        <p>{error}</p>
        <button onClick={() => navigate('/dashboard')}>Back to Dashboard</button>
      </div>
    );
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className={`dashboard-practice ${theme}`}>
      <h1>Practice: {location.state?.topic.title}</h1>
      {currentQuestion && (
        <div className="question-container">
          <h2>{currentQuestion.question}</h2>
          <div className="answers">
            {currentQuestion.answers.map((answer, index) => (
              <motion.button
                key={index}
                onClick={() => handleAnswer(answer)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {answer}
              </motion.button>
            ))}
          </div>
        </div>
      )}
      <style jsx>{`
        .dashboard-practice {
          padding: 2rem;
          background-color: ${theme === 'light' ? '#f0f4f8' : '#1a202c'};
          color: ${theme === 'light' ? '#2d3748' : '#e2e8f0'};
          min-height: 100vh;
        }
        .question-container {
          background-color: ${theme === 'light' ? '#ffffff' : '#2d3748'};
          border-radius: 8px;
          padding: 2rem;
          margin-top: 2rem;
        }
        .answers {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 1rem;
          margin-top: 1rem;
        }
        button {
          background-color: ${theme === 'light' ? '#e2e8f0' : '#4a5568'};
          color: ${theme === 'light' ? '#2d3748' : '#e2e8f0'};
          border: none;
          padding: 1rem;
          border-radius: 8px;
          cursor: pointer;
          font-size: 1rem;
        }
      `}</style>
    </div>
  );
};

export default DashboardPractice;