import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import Navbar from './components/Navbar';
import Homepage from './views/Homepage';
import Playground from './views/playground';
import Login from './views/Login';
import Profile from './views/Profile';
import Pricing from './views/Pricing';
import Settings from './views/Settings';
import Signup from './views/Signup';
import Dashboard from './views/Dashboard';
import DashboardLearn from './views/DashboardLearn';
import DashboardPractice from './views/DashboardPractice';
import DashboardVideos from './views/DashboardVideos';
import AdminDashboard from './views/AdminDashboard';
import './App.css'; 

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="app">
          <Navbar />
          <div className="main-content">
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/playground" element={<Playground />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/dashboard/learn" element={<DashboardLearn />} />
              <Route path="/dashboard/practice" element={<DashboardPractice />} />
              <Route path="/dashboard/videos" element={<DashboardVideos />} />
              <Route path="/admin" element={<AdminDashboard />} />
            </Routes>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;