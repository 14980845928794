import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaCheck } from 'react-icons/fa';

const PricingPage = () => {
  const setSelectedPlan = useState('free')[1];

  useEffect(() => {
  }, []);

  const plans = [
    {
      name: 'FREE',
      price: '$0',
      description: 'Perfect for quick learning',
      features: [
        'Access to 10 credits',
        'Daily Refresh 3 credits',
        'Perfect for quick learning'
      ],
      buttonText: 'Current Plan',
      isRecommended: false
    },
    {
      name: 'PRO',
      price: '$4.99',
      description: 'Perfect for learning and mastering diverse set of skills',
      features: [
        'Access to 300 credits',
        'Daily Refresh 5 credits',
        'Access to personal analytics',
        'Access to skill mastery analytics',
        'Perfect for high-achievers and active-learners'
      ],
      buttonText: 'Get Started',
      isRecommended: true
    },
    {
      name: 'PRO++',
      price: '$9.99',
      description: 'Perfect for powerhouses',
      features: [
        'Access to 999 credits',
        'Daily Refresh 10 credits',
        'Access to personal analytics',
        'Access to skill mastery analytics',
        'Access to extra gamification',
        'Access to your own GPT',
        'Access to gamification mastery',
        'For powerhouse users'
      ],
      buttonText: 'Get Started',
      isRecommended: false
    }
  ];

  return (
    <div className="pricing-page">
      <h2 className="pricing-title">
        Start turbo charging your learning experience!!
      </h2>
      <div className="plans-container">
        {plans.map((plan) => (
          <motion.div
            key={plan.name}
            className={`plan-card ${plan.isRecommended ? 'recommended' : ''}`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="plan-header">
              <h2 className="plan-name">{plan.name}</h2>
              {plan.isRecommended && (
                <p className="recommended-badge">Recommended plan</p>
              )}
              <p className="plan-description">{plan.description}</p>
              <p className="plan-price">
                <span className="price">{plan.price}</span>
                <span className="price-period">/month</span>
              </p>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`plan-button ${plan.name === 'FREE' ? 'current-plan' : ''}`}
                onClick={() => setSelectedPlan(plan.name.toLowerCase())}
                disabled={plan.name === 'FREE'}
              >
                {plan.buttonText}
              </motion.button>
            </div>
            <div className="plan-features">
              <h3 className="features-title">What's included</h3>
              <ul className="features-list">
                {plan.features.map((feature, index) => (
                  <li key={index} className="feature-item">
                    <FaCheck className="feature-icon" />
                    <span className="feature-text">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          </motion.div>
        ))}
      </div>
      <style jsx>{`
        .pricing-page {
          background-color: var(--background-color);
          color: var(--text-color);
          min-height: 100vh;
          padding: 60px 20px;
          font-family: 'Poppins', sans-serif;
        }
        .pricing-title {
          font-size: 2.5rem;
          font-weight: 700;
          text-align: center;
          margin-bottom: 40px;
          color: var(--primary-color);
        }
        .plans-container {
          display: flex;
          justify-content: center;
          gap: 30px;
          flex-wrap: wrap;
          max-width: 1200px;
          margin: 0 auto;
        }
        .plan-card {
          background-color: var(--card-background);
          border-radius: 12px;
          padding: 30px;
          width: 300px;
          display: flex;
          flex-direction: column;
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s ease;
        }
        .plan-card:hover {
          transform: translateY(-5px);
        }
        .plan-card.recommended {
          border: 2px solid var(--primary-color);
          position: relative;
        }
        .recommended-badge {
          position: absolute;
          top: -12px;
          left: 50%;
          transform: translateX(-50%);
          background-color: var(--primary-color);
          color: white;
          padding: 4px 12px;
          border-radius: 20px;
          font-size: 12px;
          font-weight: bold;
        }
        .plan-header {
          margin-bottom: 20px;
          text-align: center;
        }
        .plan-name {
          font-size: 1.5rem;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .plan-description {
          font-size: 1rem;
          color: var(--text-color-secondary);
          margin-bottom: 20px;
        }
        .plan-price {
          font-size: 1.5rem;
          font-weight: bold;
          margin-bottom: 20px;
          color: var(--primary-color);
        }
        .price-period {
          font-size: 1rem;
          color: var(--text-color-secondary);
        }
        .plan-button {
          width: 100%;
          padding: 12px;
          background-color: var(--primary-color);
          color: white;
          border: none;
          border-radius: 8px;
          font-size: 1rem;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }
        .plan-button:hover {
          background-color: var(--primary-color-dark);
        }
        .plan-button.current-plan {
          background-color: #666;
          cursor: not-allowed;
        }
        .features-title {
          font-size: 1rem;
          text-transform: uppercase;
          color: var(--text-color-secondary);
          margin-bottom: 10px;
        }
        .features-list {
          list-style-type: none;
          padding: 0;
        }
        .feature-item {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
        }
        .feature-icon {
          color: var(--primary-color);
          margin-right: 10px;
        }
        .feature-text {
          font-size: 1rem;
        }
      `}</style>
    </div>
  );
};

export default PricingPage;
