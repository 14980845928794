import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { db, doc, getDoc, updateDoc, increment, arrayUnion } from '../firebaseConfig';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { FaSearch, FaRocket, FaBook, FaLightbulb, FaGraduationCap, FaLock, FaMagic, FaSave, FaInfoCircle } from 'react-icons/fa';
import Select from 'react-select';

const Playground = () => {
  const { user, theme } = useAuth();
  const [searchQuery, setSearchQuery] = useState('');
  const [roadmap, setRoadmap] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [showRoadmap, setShowRoadmap] = useState(false);
  const [aura, setAura] = useState(160);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isSearchBarMinimized, setIsSearchBarMinimized] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [skillLevel, setSkillLevel] = useState(null);
  const [showContextModal, setShowContextModal] = useState(false);
  const [showCongratulationsModal, setShowCongratulationsModal] = useState(false);
  const [context, setContext] = useState({
    description: '',
    duration: '',
    additional: ''
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const fetchAura = async () => {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        setAura(userDoc.data().aura);
      };
      fetchAura();
    }
  }, [user]);

  const skillLevelOptions = [
    { value: 'beginner', label: 'Beginner', color: '#4CAF50' },
    { value: 'intermediate', label: 'Intermediate', color: '#FFA500' },
    { value: 'advanced', label: 'Advanced', color: '#f44336' }
  ];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'none',
      cursor: 'pointer'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? state.data.color : 'transparent',
      color: state.isSelected ? 'white' : state.data.color,
      ':hover': {
        backgroundColor: state.data.color,
        color: 'white'
      }
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      fontWeight: 'bold'
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.selectProps.menuIsOpen ? (state.data ? state.data.color : 'gray') : 'gray'
    })
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!skillLevel) {
      setError('Please select a skill level before searching.');
      return;
    }
    if (!user) {
      setShowLoginModal(true);
      return;
    }
    if (aura < 40) {
      setError('Not enough aura to perform this action.');
      return;
    }
    setIsLoading(true);
    setError(null);
    setProgress(0);
    setShowRoadmap(false);
    setIsSearchBarMinimized(true);
    setRoadmap([]);
  
    const contextPrompt = context.description || context.duration || context.additional
      ? `Additional context:
         Description: ${context.description}
         Desired duration: ${context.duration}
         Additional information: ${context.additional}`
      : '';
  
    try {
      const response = await axios.post('https://masterfy.onrender.com/api/chat', {
        message: `Generate a 7-step learning roadmap to master ${searchQuery}. The first step should be "START" and the last step should be "MASTERED". Each step should be a big topic with a very short description. The roadmap should be tailored to the user's current skill level, which is ${skillLevel.value}. ${contextPrompt}`
      }, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        }
      });
  
      const steps = response.data.response.split('\n\n').filter(step => step.trim() !== '').map((step, index) => {
        const [title, description] = step.split('\n');
        return { 
          id: index + 1,
          title: title.replace(/^\d+\.\s*/, '').replace(/\*\*/g, ''), 
          description: description ? description.replace(/\*/g, '') : '',
          completed: false
        };
      });
  
      setRoadmap(steps);
      setIsLoading(false);
      setShowRoadmap(true);
  
      // Save the search query and roadmap to the user's document
      await updateDoc(doc(db, 'users', user.uid), {
        [`searches.${searchQuery}`]: {
          skillLevel: skillLevel.value,
          roadmap: steps,
          timestamp: new Date().toISOString()
        },
        aura: increment(-40),
        'stats.skillsSearched': increment(1)
      });
      setAura(aura - 40);
  
      // Check if this is the user's first roadmap
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (!userDoc.data().hasGeneratedRoadmap) {
        setShowCongratulationsModal(true);
        await updateDoc(doc(db, 'users', user.uid), {
          hasGeneratedRoadmap: true
        });
      }
    } catch (err) {
      setError('Failed to generate roadmap. Please try again.');
      console.error('Error:', err);
      setIsLoading(false);
    }
  };

  const handleSaveRoadmap = async () => {
    if (!user) {
      setShowLoginModal(true);
      return;
    }
    setIsSaving(true);
    try {
      await updateDoc(doc(db, 'users', user.uid), {
        savedRoadmaps: arrayUnion({
          id: Date.now(),
          query: searchQuery,
          skillLevel: skillLevel.value,
          context,
          steps: roadmap
        })
      });
      setIsSaving(false);
    } catch (err) {
      console.error('Error saving roadmap:', err);
      setIsSaving(false);
    }
  };

  const handleTopicClick = (topic) => {
    navigate('/dashboard', { state: { selectedTopic: topic, roadmap: roadmap, searchQuery: searchQuery } });
  };

  return (
    <div className={`playground ${theme}`}>
      <motion.div 
        className="search-container"
        initial={{ y: 0, scale: 1, width: '80%', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000 }}
        animate={isSearchBarMinimized ? { 
          y: -window.innerHeight / 2 + 80,
          scale: 0.8,
          width: '80%',
          position: 'fixed',
          top: '80px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1000 
        } : { 
          y: 0, 
          scale: 1,
          width: '80%',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1000
        }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        <form onSubmit={handleSearch} className="search-form">
          <FaMagic className="magic-icon" />
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Enter a skill you want to master..."
            className="search-input"
          />
          <Select
            options={skillLevelOptions}
            styles={customStyles}
            value={skillLevel}
            onChange={setSkillLevel}
            placeholder="Your Skill Level"
            className="skill-level-select"
          />
          <motion.button 
            type="button" 
            className="context-button"
            onClick={() => setShowContextModal(true)}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaInfoCircle />
          </motion.button>
          <motion.button 
            type="submit" 
            className="search-button"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            disabled={!skillLevel}
          >
            <FaSearch />
          </motion.button>
        </form>
      </motion.div>

      <AnimatePresence>
        {isLoading && (
          <motion.div 
            className="loading-container"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center'
            }}
          >
            <div className="loading-bar-container">
              <motion.div 
                className="loading-bar" 
                initial={{ width: 0 }}
                animate={{ width: `${progress}%` }}
                transition={{ duration: 0.5 }}
              ></motion.div>
            </div>
            <p>Crafting your personalized learning journey... {progress}%</p>
          </motion.div>
        )}
      </AnimatePresence>

      {error && <div className="error">{error}</div>}

      <AnimatePresence>
        {showRoadmap && (
          <motion.div 
            className="roadmap-container"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
          >
            <div className="roadmap-header">
              <h2>Your Learning Roadmap</h2>
              <motion.button 
                className="save-roadmap-button"
                onClick={handleSaveRoadmap}
                disabled={isSaving}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {isSaving ? 'Saving...' : 'Save Roadmap'} <FaSave />
              </motion.button>
            </div>
            <div className="roadmap-content">
              {roadmap.map((step) => (
                <motion.div 
                  key={step.id} 
                  className="roadmap-step"
                  whileHover={{ scale: 1.05 }}
                  onClick={() => handleTopicClick(step)}
                >
                  <div className="step-icon">
                    {step.id % 4 === 1 && <FaRocket />}
                    {step.id % 4 === 2 && <FaBook />}
                    {step.id % 4 === 3 && <FaLightbulb />}
                    {step.id % 4 === 0 && <FaGraduationCap />}
                  </div>
                  <div className="step-content">
                    <h3>{step.title}</h3>
                    <p>{step.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showLoginModal && (
          <motion.div 
            className="modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div 
              className="login-modal"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
            >
              <FaLock className="lock-icon" />
              <h2>Oops! You're not logged in yet</h2>
              <p>Log in to unlock your personalized learning journey.</p>
              <motion.button 
                onClick={() => navigate('/login')}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Let's Get Started!
              </motion.button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showContextModal && (
          <motion.div 
            className="modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div 
              className="context-modal"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
            >
              <h2>Add Context to Your Learning Journey</h2>
              <textarea
                placeholder="Describe a little more about what you want to learn: (optional)"
                value={context.description}
                onChange={(e) => setContext({...context, description: e.target.value})}
              />
              <input
                type="text"
                placeholder="How long do you want to take to master this skill: (optional)"
                value={context.duration}
                onChange={(e) => setContext({...context, duration: e.target.value})}
              />
              <textarea
                placeholder="Anything else? (optional)"
                value={context.additional}
                onChange={(e) => setContext({...context, additional: e.target.value})}
              />
              <motion.button 
                onClick={() => setShowContextModal(false)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Submit
              </motion.button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showCongratulationsModal && (
          <motion.div 
            className="modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div 
              className="congratulations-modal"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
            >
              <h2>Congratulations!</h2>
              <p>You've generated your first roadmap! Click on any of the big topics to start your personalized journey!</p>
              <motion.button 
                onClick={() => setShowCongratulationsModal(false)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Got it!
              </motion.button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <style jsx>{`
        .playground {
          font-family: 'Poppins', sans-serif;
          background-color: ${theme === 'light' ? '#FFF5E6' : '#2A2A2A'};
          color: ${theme === 'light' ? '#333333' : '#FFFFFF'};
          min-height: 100vh;
          padding: 20px;
          transition: background-color 0.3s ease, color 0.3s ease;
        }

        .search-container {
          width: 80%;
          max-width: 800px;
          margin: 0 auto;
          padding: 20px;
          background-color: ${theme === 'light' ? '#FFFFFF' : '#3A3A3A'};
          border-radius: 20px;
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
          transition: all 0.5s ease;
          z-index: 1000;
        }

        .search-form {
          display: flex;
          align-items: center;
        }

        .magic-icon {
          font-size: 24px;
          color: #7a6add;
          margin-right: 15px;
        }

        .search-input {
          flex: 1;
          padding: 12px 20px;
          font-size: 16px;
          border: none;
          background-color: transparent;
          color: ${theme === 'light' ? '#333333' : '#FFFFFF'};
        }

        .search-input::placeholder {
          color: ${theme === 'light' ? '#999999' : '#CCCCCC'};
        }

        .skill-level-select {
          width: 150px;
          margin: 0 15px;
        }

        .context-button,
        .search-button {
          background-color: #7a6add;
          color: #FFFFFF;
          border: none;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all 0.3s ease;
          margin-left: 10px;
        }

        .context-button {
          background-color: #4CAF50;
        }

        .context-button:hover,
        .search-button:hover {
          transform: scale(1.1);
        }

        .context-button:active,
        .search-button:active {
          transform: scale(0.95);
        }

        .loading-container {
          text-align: center;
          margin-top: 40px;
        }

        .loading-bar-container {
          width: 80%;
          height: 6px;
          background-color: ${theme === 'light' ? '#E0E0E0' : '#4A4A4A'};
          border-radius: 3px;
          margin: 0 auto 20px;
          overflow: hidden;
        }

        .loading-bar {
          height: 100%;
          background-color: #7a6add;
          transition: width 0.3s ease;
        }

        .error {
          color: #FF0000;
          text-align: center;
          margin-top: 20px;
          font-weight: 600;
        }

        .roadmap-container {
          max-width: 1000px;
          margin: 40px auto 0;
          padding: 30px;
          background-color: ${theme === 'light' ? '#FFFFFF' : '#3A3A3A'};
          border-radius: 20px;
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
        }

        .roadmap-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 30px;
        }

        .roadmap-header h2 {
          font-size: 24px;
          color: #7a6add;
          margin: 0;
        }

        .save-roadmap-button {
          background-color: #7a6add;
          color: #FFFFFF;
          border: none;
          padding: 10px 20px;
          border-radius: 20px;
          font-size: 16px;
          cursor: pointer;
          transition: all 0.3s ease;
          display: flex;
          align-items: center;
        }

        .save-roadmap-button svg {
          margin-left: 10px;
        }

        .save-roadmap-button:hover {
          background-color: #E68A00;
        }

        .save-roadmap-button:disabled {
          background-color: #CCCCCC;
          cursor: not-allowed;
        }

        .roadmap-content {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          gap: 20px;
        }

        .roadmap-step {
          background-color: ${theme === 'light' ? '#FFF5E6' : '#4A4A4A'};
          border-radius: 10px;
          padding: 20px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          cursor: pointer;
          transition: all 0.3s ease;
        }

        .roadmap-step:hover {
          transform: translateY(-5px);
        }

        .step-icon {
          background-color: #7a6add;
          color: #FFFFFF;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          margin-bottom: 15px;
        }

        .step-content h3 {
          font-size: 18px;
          color: #7a6add;
          margin: 0 0 10px;
        }

        .step-content p {
          font-size: 14px;
          color: ${theme === 'light' ? '#666666' : '#CCCCCC'};
        }

        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.7);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .login-modal,
        .context-modal,
        .congratulations-modal {
          background-color: ${theme === 'light' ? '#FFFFFF' : '#3A3A3A'};
          padding: 30px;
          border-radius: 20px;
          width: 90%;
          max-width: 400px;
          text-align: center;
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
        }

        .lock-icon {
          font-size: 48px;
          color: #7a6add;
          margin-bottom: 20px;
        }

        .login-modal h2,
        .context-modal h2,
        .congratulations-modal h2 {
          font-size: 24px;
          color: #7a6add;
          margin-bottom: 15px;
        }

        .login-modal p,
        .congratulations-modal p {
          font-size: 16px;
          color: ${theme === 'light' ? '#666666' : '#CCCCCC'};
          margin-bottom: 20px;
        }

        .login-modal button,
        .context-modal button,
        .congratulations-modal button {
          background-color: #7a6add;
          color: #FFFFFF;
          border: none;
          padding: 10px 20px;
          border-radius: 20px;
          font-size: 16px;
          cursor: pointer;
          transition: all 0.3s ease;
        }

        .login-modal button:hover,
        .context-modal button:hover,
        .congratulations-modal button:hover {
          background-color: #E68A00;
        }

        .context-modal textarea,
        .context-modal input {
          width: 100%;
          padding: 10px;
          margin-bottom: 15px;
          border: 1px solid ${theme === 'light' ? '#E0E0E0' : '#666666'};
          border-radius: 5px;
          background-color: ${theme === 'light' ? '#FFFFFF' : '#4A4A4A'};
          color: ${theme === 'light' ? '#333333' : '#FFFFFF'};
          font-size: 14px;
        }

        .context-modal textarea {
          height: 100px;
          resize: vertical;
        }

        @media (max-width: 768px) {
          .search-container {
            width: 95%;
            padding: 15px;
          }

          .search-input {
            font-size: 14px;
            padding: 10px 15px;
          }

          .skill-level-select {
            width: 120px;
            margin: 0 10px;
          }

          .context-button,
          .search-button {
            width: 35px;
            height: 35px;
          }

          .roadmap-container {
            padding: 20px;
          }

          .roadmap-header h2 {
            font-size: 20px;
          }

          .save-roadmap-button {
            font-size: 14px;
            padding: 8px 16px;
          }

          .step-icon {
            width: 30px;
            height: 30px;
            font-size: 16px;
          }

          .step-content h3 {
            font-size: 16px;
          }

          .step-content p {
            font-size: 12px;
          }
        }
      `}</style>
    </div>
  );
};

export default Playground;
