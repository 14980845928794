import React from 'react';
import { useAuth } from '../AuthContext';
import { FaCog, FaBell, FaLock, FaPalette } from 'react-icons/fa';

const SettingsSection = ({ icon, title, children, theme }) => (
  <div className={`settings-section ${theme}`}>
    <div className="section-header">
      {icon}
      <h2>{title}</h2>
    </div>
    {children}
    <style jsx>{`
      .settings-section {
        background-color: ${theme === 'light' ? '#fff' : '#2a2a2a'};
        border-radius: 10px;
        padding: 1.5rem;
        margin-bottom: 2rem;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      }
      .section-header {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
      }
      .section-header svg {
        font-size: 1.5rem;
        margin-right: 0.5rem;
        color: #6a5acd;
      }
      h2 {
        font-size: 1.2rem;
        margin: 0;
      }
    `}</style>
  </div>
);

const Settings = () => {
  const { user, signOut, theme, toggleTheme } = useAuth();

  const handleLogout = async () => {
    await signOut();
  };

  return (
    <div className={`settings-page ${theme}`}>
      <h1>Settings</h1>
      <SettingsSection icon={<FaCog />} title="General" theme={theme}>
        <p>Email: {user.email}</p>
        <button onClick={handleLogout} className="logout-button">Log out</button>
      </SettingsSection>
      <SettingsSection icon={<FaBell />} title="Notifications" theme={theme}>
        <label>
          <input
            type="checkbox"
            defaultChecked={true}
          />
          Receive email notifications
        </label>
      </SettingsSection>
      <SettingsSection icon={<FaLock />} title="Security" theme={theme}>
        <button className="change-password-button">Change Password</button>
      </SettingsSection>
      <SettingsSection icon={<FaPalette />} title="Appearance" theme={theme}>
        <label>
          <input
            type="checkbox"
            checked={theme === 'dark'}
            onChange={toggleTheme}
          />
          Dark Mode
        </label>
      </SettingsSection>
      <style jsx>{`
        .settings-page {
          padding: 80px 20px;
          background-color: ${theme === 'light' ? '#f7f7f7' : '#1a1a1a'};
          color: ${theme === 'light' ? '#333' : '#f7f7f7'};
          min-height: 100vh;
        }
        h1 {
          font-size: 2rem;
          margin-bottom: 2rem;
        }
        label {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
        }
        input[type="checkbox"] {
          margin-right: 0.5rem;
        }
        .logout-button, .change-password-button {
          background-color: #6a5acd;
          color: white;
          border: none;
          border-radius: 5px;
          padding: 0.5rem 1rem;
          font-size: 1rem;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }
        .logout-button:hover, .change-password-button:hover {
          background-color: #5a4cad;
        }
      `}</style>
    </div>
  );
};

export default Settings;