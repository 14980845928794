import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword } from '../firebaseConfig';
import { FaGoogle } from 'react-icons/fa';
import { useAuth } from '../AuthContext';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { theme } = useAuth();

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, new GoogleAuthProvider());
      navigate('/playground');
    } catch (error) {
      setError('Error signing in with Google. Please try again.');
      console.error('Error signing in with Google', error);
    }
  };

  const handleEmailSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/playground');
    } catch (error) {
      setError('Error signing in. Please check your email and password.');
      console.error('Error signing in with email', error);
    }
  };

  return (
    <div className={`login-page ${theme}`}>
      <div className="login-container">
        <div className="login-form">
          <h1>Welcome back</h1>
          <p>Sign in to your account</p>
          <button className="social-button google" onClick={handleGoogleSignIn}>
            <FaGoogle /> Continue with Google
          </button>
          <div className="divider">or</div>
          <form onSubmit={handleEmailSignIn}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {error && <p className="error-message">{error}</p>}
            <button type="submit" className="login-button">Sign In</button>
          </form>
          <p className="signup-link">
            Don't have an account? <a href="/signup">Sign up</a>
          </p>
        </div>
        <div className="login-sidebar">
          <div className="testimonial">
            <p>"Masterfy is amazing. It literally saves our small team a whole engineer's worth of work constantly. The founders and everyone I've chatted with are just awesome people as well :)"</p>
            <div className="author">
              <img src="https://randomuser.me/api/portraits/men/32.jpg" alt="Author" />
              <span>Ryan Chen, CEO @CozLabs</span>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .login-page {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 100vh;
          background-color: var(--background-color);
          color: var(--text-color);
        }
        .login-container {
          display: flex;
          width: 900px;
          background-color: var(--card-background);
          border-radius: 10px;
          overflow: hidden;
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
        }
        .login-form {
          flex: 1;
          padding: 3rem;
        }
        h1 {
          font-size: 2.5rem;
          margin-bottom: 0.5rem;
          color: var(--primary-color);
        }
        p {
          margin-bottom: 2rem;
          color: var(--text-color);
        }
        .social-button {
          width: 100%;
          padding: 0.8rem;
          margin-bottom: 1rem;
          border: none;
          border-radius: 5px;
          font-size: 1rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease;
        }
        .social-button svg {
          margin-right: 0.5rem;
        }
        .google {
          background-color: #4285f4;
          color: white;
        }
        .google:hover {
          background-color: #3367d6;
          transform: translateY(-2px);
          box-shadow: 0 5px 15px rgba(66, 133, 244, 0.3);
        }
        .divider {
          text-align: center;
          margin: 1rem 0;
          color: var(--text-color);
        }
        .divider::before,
        .divider::after {
          content: '';
          display: inline-block;
          width: 40%;
          height: 1px;
          background-color: var(--text-color);
          margin: 0 0.5rem;
          vertical-align: middle;
        }
        form {
          display: flex;
          flex-direction: column;
        }
        input {
          padding: 0.8rem;
          margin-bottom: 1rem;
          border: 1px solid var(--text-color);
          border-radius: 5px;
          font-size: 1rem;
          background-color: var(--background-color);
          color: var(--text-color);
        }
        .error-message {
          color: #ff6b6b;
          margin-bottom: 1rem;
        }
        .login-button {
          padding: 0.8rem;
          background-color: var(--primary-color);
          color: white;
          border: none;
          border-radius: 5px;
          font-size: 1rem;
          cursor: pointer;
          transition: all 0.3s ease;
        }
        .login-button:hover {
          background-color: ${theme === 'light' ? '#5a4cad' : '#7a6add'};
          transform: translateY(-2px);
          box-shadow: 0 5px 15px rgba(106, 90, 205, 0.3);
        }
        .signup-link {
          margin-top: 1rem;
          text-align: center;
        }
        .signup-link a {
          color: var(--primary-color);
          text-decoration: none;
          font-weight: bold;
        }
        .login-sidebar {
          flex: 1;
          background-color: var(--primary-color);
          padding: 3rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .testimonial {
          color: white;
          text-align: center;
        }
        .testimonial p {
          font-size: 1.2rem;
          font-style: italic;
          margin-bottom: 2rem;
        }
        .author {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .author img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 1rem;
        }
        @media (max-width: 768px) {
          .login-container {
            flex-direction: column;
            width: 100%;
            height: 100%;
            border-radius: 0;
          }
          .login-sidebar {
            display: none;
          }
        }
      `}</style>
    </div>
  );
};

export default Login;