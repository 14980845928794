import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import { FaArrowLeft, FaChild, FaGraduationCap, FaChalkboardTeacher, FaLightbulb } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';

const LearnContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: ${props => props.theme === 'light' ? '#333' : '#f0f0f0'};
  background-color: ${props => props.theme === 'light' ? '#f0f4f8' : '#1a1a1a'};
  overflow: hidden;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: ${props => props.theme === 'light' ? '#e0e0e0' : '#2a2a2a'};
`;

const Title = styled.h1`
  font-size: 2.5rem;
  background: linear-gradient(to right, #00ccb1, #7b61ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: #7b61ff;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #6a50e0;
  }

  @media (max-width: 768px) {
    padding: 8px 12px;
    font-size: 0.9rem;
  }
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  gap: 20px;
  flex-grow: 1;
  overflow: hidden;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 2fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Sidebar = styled.div`
  overflow-y: auto;
  padding: 20px;
  background-color: ${props => props.theme === 'light' ? '#e8e8e8' : '#252525'};

  @media (max-width: 768px) {
    display: none;
  }
`;

const MainContent = styled.div`
  overflow-y: auto;
  padding: 20px;
  background-color: ${props => props.theme === 'light' ? '#f8f8f8' : '#1e1e1e'};
`;

const RightSidebar = styled.div`
  overflow-y: auto;
  padding: 20px;
  background-color: ${props => props.theme === 'light' ? '#e8e8e8' : '#252525'};

  @media (max-width: 1024px) {
    display: none;
  }
`;

const BentoBox = styled.div`
  background-color: ${props => props.theme === 'light' ? 'white' : '#2a2a2a'};
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const PerspectiveButton = styled(motion.button)`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: ${props => props.isActive ? '#7b61ff' : props.theme === 'light' ? '#e0e0e0' : '#3a3a3a'};
  color: ${props => props.isActive ? 'white' : props.theme === 'light' ? '#333' : '#f0f0f0'};
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => props.isActive ? '#6a50e0' : props.theme === 'light' ? '#d0d0d0' : '#4a4a4a'};
  }
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Message = styled.div`
  background-color: ${props => props.$isUser ? 
    (props.theme === 'light' ? '#e1f5fe' : '#1e3a5f') : 
    (props.theme === 'light' ? '#f0f0f0' : '#2a2a2a')};
  color: ${props => props.theme === 'light' ? '#333' : '#f0f0f0'};
  border-radius: 10px;
  padding: 10px 15px;
  margin-bottom: 10px;
  max-width: 80%;
  align-self: ${props => props.$isUser ? 'flex-end' : 'flex-start'};
`;

const InputContainer = styled.div`
  padding: 20px;
  background-color: ${props => props.theme === 'light' ? '#e0e0e0' : '#2a2a2a'};
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  background-color: ${props => props.theme === 'light' ? 'white' : '#333'};
  color: ${props => props.theme === 'light' ? '#333' : 'white'};
`;

const HighlightPopup = styled.div`
  position: absolute;
  background-color: ${props => props.theme === 'light' ? 'white' : '#333'};
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;

const SavedChatItem = styled.div`
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: ${props => props.theme === 'light' ? '#f0f0f0' : '#333'};
  &:hover {
    background-color: ${props => props.theme === 'light' ? '#e0e0e0' : '#444'};
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${props => props.theme === 'light' ? '#f0f4f8' : '#1a1a1a'};
  color: ${props => props.theme === 'light' ? '#333' : '#f0f0f0'};
`;

const LoadingBar = styled.div`
  width: 200px;
  height: 6px;
  background-color: ${props => props.theme === 'light' ? '#e0e0e0' : '#333'};
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 20px;

  &::after {
    content: '';
    display: block;
    width: 40%;
    height: 100%;
    background-color: #7b61ff;
    animation: loading 1.5s infinite ease-in-out;
  }

  @keyframes loading {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(250%); }
  }
`;

const DidYouKnow = styled.div`
  font-style: italic;
  color: #7b61ff;
  margin-top: 20px;
  text-align: center;
`;

const QuickFact = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: ${props => props.theme === 'light' ? '#f0f0f0' : '#333'};
  border-radius: 5px;

  svg {
    margin-right: 10px;
    color: #7b61ff;
  }
`;

const AnimatedTyping = styled.div`
  display: inline-block;
  overflow: hidden;
  animation: typing 2s steps(40, end), blink-caret 0.75s step-end infinite;

  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }

  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: #7b61ff; }
  }
`;

const DashboardLearn = () => {
  const { theme } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [perspective, setPerspective] = useState('student');
  const [savedChats, setSavedChats] = useState([]);
  const [quickFacts, setQuickFacts] = useState([]);
  const [highlightPopup, setHighlightPopup] = useState({ visible: false, x: 0, y: 0 });
  const [highlightedText, setHighlightedText] = useState('');
  const [userInput, setUserInput] = useState('');
  const [conversation, setConversation] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [isTyping, setIsTyping] = useState(false);

  const mainContentRef = useRef(null);
  const highlightInputRef = useRef(null);

  const generateContent = useCallback(async (topic, currentPerspective) => {
    if (!topic) {
      setError('No topic selected. Please go back to the dashboard and select a topic.');
      setIsLoading(false);
      return;
    }
    
    setIsLoading(true);
    try {
      const response = await axios.post('https://masterfy.onrender.com/api/chat', {
        message: `Generate a detailed explanation about ${topic.title} from a ${currentPerspective}'s perspective. Include key concepts, examples, and best practices. Format the response with proper Markdown syntax, including headings, subheadings, bold text, and bullet points.`
      });
      setConversation([{ role: 'assistant', content: response.data.response }]);

      // Generate quick facts
      const factsResponse = await axios.post('https://masterfy.onrender.com/api/chat', {
        message: `Generate 3 quick facts about ${topic.title}. Keep each fact concise and interesting.`
      });
      setQuickFacts(factsResponse.data.response.split('\n').filter(fact => fact.trim() !== ''));

      // Save chat automatically
      const newChat = {
        id: Date.now(),
        title: topic.title,
        perspective: currentPerspective,
        content: [{ role: 'assistant', content: response.data.response }]
      };
      setSavedChats(prevChats => [...prevChats, newChat]);
    } catch (err) {
      setError('Failed to generate content. Please try again.');
      console.error('Error:', err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (location.state?.topic) {
      generateContent(location.state.topic, perspective);
    } else {
      setError('No topic selected. Please go back to the dashboard and select a topic.');
      setIsLoading(false);
    }
  }, [location.state, generateContent, perspective]);

  const handleTextSelection = () => {
    const selection = window.getSelection();
    if (selection.toString().length > 0) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      setHighlightPopup({
        visible: true,
        x: rect.left + window.scrollX,
        y: rect.bottom + window.scrollY
      });
      setHighlightedText(selection.toString());
      if (highlightInputRef.current) {
        highlightInputRef.current.focus();
      }
    } else {
      setHighlightPopup({ visible: false, x: 0, y: 0 });
    }
  };

  const handleHighlightSubmit = async (e) => {
    e.preventDefault();
    const userQuery = e.target.query.value;
    setHighlightPopup({ visible: false, x: 0, y: 0 });

    try {
      setIsTyping(true);
      const response = await axios.post('https://masterfy.onrender.com/api/chat', {
        message: `Context: "${highlightedText}"\nUser Query: ${userQuery}\nPlease provide a response to the user's query based on the given context.`
      });
      const newConversation = [...conversation, { role: 'user', content: userQuery }, { role: 'assistant', content: response.data.response }];
      setConversation(newConversation);
      updateSavedChat(newConversation);
    } catch (err) {
      console.error('Error:', err);
    } finally {
      setIsTyping(false);
    }
  };

  const handleUserInput = async (e) => {
    e.preventDefault();
    if (!userInput.trim()) return;

    const newConversation = [...conversation, { role: 'user', content: userInput }];
    setConversation(newConversation);
    setUserInput('');

    try {
      setIsTyping(true);
      const response = await axios.post('https://masterfy.onrender.com/api/chat', {
        message: `User: ${userInput}\nAssistant: Please respond to the user's message in the context of the current topic (${location.state?.topic?.title}).`
      });
      const updatedConversation = [...newConversation, { role: 'assistant', content: response.data.response }];
      setConversation(updatedConversation);
      updateSavedChat(updatedConversation);} catch (err) {
        console.error('Error:', err);
      } finally {
        setIsTyping(false);
      }
    };
  
    const updateSavedChat = (newConversation) => {
      setSavedChats(prevChats => {
        const updatedChats = prevChats.map(chat => 
          chat.id === selectedChat?.id ? { ...chat, content: newConversation } : chat
        );
        if (!selectedChat) {
          updatedChats.push({
            id: Date.now(),
            title: location.state?.topic?.title,
            perspective: perspective,
            content: newConversation
          });
        }
        return updatedChats;
      });
    };
  
    const handleChatSelect = (chat) => {
      setSelectedChat(chat);
      setConversation(chat.content);
      setPerspective(chat.perspective);
    };
  
    const handlePerspectiveChange = (newPerspective) => {
      setPerspective(newPerspective);
      generateContent(location.state?.topic, newPerspective);
    };
  
    if (isLoading) {
      return (
        <LoadingContainer theme={theme}>
          <LoadingBar theme={theme} />
          <h2>Preparing your personalized learning experience...</h2>
          <DidYouKnow>
            Did you know? Practicing the same concept twice a day has been proven to help you master it 80% faster!
          </DidYouKnow>
        </LoadingContainer>
      );
    }
  
    if (error) {
      return (
        <LearnContainer theme={theme}>
          <p>{error}</p>
          <Button onClick={() => navigate('/dashboard')}><FaArrowLeft /> Back to Dashboard</Button>
        </LearnContainer>
      );
    }
  
    return (
      <LearnContainer theme={theme}>
        <Header theme={theme}>
          <Title>{location.state?.topic?.title || 'Learn'}</Title>
          <ButtonGroup>
            <Button onClick={() => navigate('/dashboard')}><FaArrowLeft /> Back to Dashboard</Button>
          </ButtonGroup>
        </Header>
        <ContentGrid>
          <Sidebar theme={theme}>
            <BentoBox theme={theme}>
              <h3>Saved Chats</h3>
              {savedChats.map((chat) => (
                <SavedChatItem 
                  key={chat.id} 
                  onClick={() => handleChatSelect(chat)} 
                  theme={theme}
                >
                  <h4>{chat.title}</h4>
                  <p>Perspective: {chat.perspective}</p>
                </SavedChatItem>
              ))}
            </BentoBox>
          </Sidebar>
          <MainContent theme={theme} ref={mainContentRef} onMouseUp={handleTextSelection}>
            <MessageContainer>
              {conversation.map((msg, index) => (
                <Message key={index} $isUser={msg.role === 'user'} theme={theme}>
                  <ReactMarkdown>{msg.content}</ReactMarkdown>
                </Message>
              ))}
              {isTyping && (
                <Message theme={theme}>
                  <AnimatedTyping>Finding you the best answer...</AnimatedTyping>
                </Message>
              )}
            </MessageContainer>
            {highlightPopup.visible && (
              <HighlightPopup style={{ top: highlightPopup.y, left: highlightPopup.x }} theme={theme}>
                <form onSubmit={handleHighlightSubmit}>
                  <Input 
                    type="text" 
                    name="query" 
                    placeholder="Ask about this text" 
                    theme={theme}
                    ref={highlightInputRef}
                    autoFocus
                  />
                  <Button type="submit">Ask</Button>
                </form>
              </HighlightPopup>
            )}
          </MainContent>
          <RightSidebar theme={theme}>
            <BentoBox theme={theme}>
              <h3>Explain the topic as a...</h3>
              <PerspectiveButton
                theme={theme}
                isActive={perspective === 'toddler'}
                onClick={() => handlePerspectiveChange('toddler')}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Avatar src="https://api.dicebear.com/6.x/bottts/svg?seed=toddler" alt="Toddler" />
                <span>Toddler</span>
                <FaChild />
              </PerspectiveButton>
              <PerspectiveButton
                theme={theme}
                isActive={perspective === 'student'}
                onClick={() => handlePerspectiveChange('student')}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Avatar src="https://api.dicebear.com/6.x/bottts/svg?seed=student" alt="Student" />
                <span>Student</span>
                <FaGraduationCap />
              </PerspectiveButton>
              <PerspectiveButton
                theme={theme}
                isActive={perspective === 'teacher'}
                onClick={() => handlePerspectiveChange('teacher')}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Avatar src="https://api.dicebear.com/6.x/bottts/svg?seed=teacher" alt="Teacher" />
                <span>Teacher</span>
                <FaChalkboardTeacher />
              </PerspectiveButton>
            </BentoBox>
            <BentoBox theme={theme}>
              <h3>Quick Facts</h3>
              {quickFacts.map((fact, index) => (
                <QuickFact key={index} theme={theme}>
                  <FaLightbulb />
                  <span>{fact}</span>
                </QuickFact>
              ))}
            </BentoBox>
          </RightSidebar>
        </ContentGrid>
        <InputContainer theme={theme}>
          <form onSubmit={handleUserInput}>
            <Input
              type="text"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              placeholder="Ask a question or type a message..."
              theme={theme}
            />
          </form>
        </InputContainer>
      </LearnContainer>
    );
  };
  
  export default DashboardLearn;