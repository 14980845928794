import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { db, doc, getDoc } from '../firebaseConfig';
import {
  FaUser,
  FaEnvelope,
  FaHistory,
  FaRocket,
  FaBook,
  FaLightbulb,
  FaGraduationCap,
  FaTimes,
} from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import ReactMarkdown from 'react-markdown';

const ProfileCard = ({ icon, title, value, theme }) => (
  <div className={`profile-card ${theme}`}>
    {icon}
    <h3>{title}</h3>
    <p>{value}</p>
    <style jsx>{`
      .profile-card {
        background-color: var(--card-background);
        border-radius: 10px;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
      }
      .profile-card:hover {
        transform: translateY(-5px);
      }
      .profile-card svg {
        font-size: 2rem;
        color: var(--primary-color);
        margin-bottom: 1rem;
      }
      h3 {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
      }
      p {
        font-size: 1rem;
        color: var(--text-color);
      }
    `}</style>
  </div>
);

const RoadmapCard = ({ roadmap, theme, onView }) => (
  <div className={`roadmap-card ${theme}`}>
    <h3>{roadmap.query}</h3>
    <p>Skill Level: {roadmap.skillLevel}</p>
    <div className="step-icons">
      {roadmap.steps.map((step, index) => (
        <div key={index} className={`step-icon ${step.completed ? 'completed' : ''}`}>
          {index % 4 === 0 && <FaRocket />}
          {index % 4 === 1 && <FaBook />}
          {index % 4 === 2 && <FaLightbulb />}
          {index % 4 === 3 && <FaGraduationCap />}
        </div>
      ))}
    </div>
    <button className="view-roadmap-button" onClick={() => onView(roadmap)}>View Roadmap</button>
    <style jsx>{`
      .roadmap-card {
        background-color: var(--card-background);
        border-radius: 10px;
        padding: 1.5rem;
        margin-bottom: 1rem;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
      }
      .roadmap-card:hover {
        transform: translateY(-5px);
      }
      h3 {
        font-size: 1.2rem;
        margin-bottom: 1rem;
        color: var(--primary-color);
      }
      p {
        font-size: 1rem;
        color: var(--text-color);
        margin-bottom: 1rem;
      }
      .step-icons {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
      }
      .step-icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: ${theme === 'light' ? '#e2e8f0' : '#4a5568'};
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--text-color);
      }
      .step-icon.completed {
        background-color: var(--primary-color);
        color: #fff;
      }
      .view-roadmap-button {
        background-color: var(--primary-color);
        color: #fff;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 20px;
        cursor: pointer;
        transition: all 0.3s ease;
      }
      .view-roadmap-button:hover {
        background-color: ${theme === 'light' ? '#5a4cad' : '#7a6add'};
      }
    `}</style>
  </div>
);

const Profile = () => {
  const { user, theme } = useAuth();
  const [profile, setProfile] = useState(null);
  const [savedRoadmaps, setSavedRoadmaps] = useState([]);
  const [selectedRoadmap, setSelectedRoadmap] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const userData = userDoc.data();
        setProfile(userData);
        setSavedRoadmaps(userData.savedRoadmaps || []);
      }
    };
    fetchProfile();
  }, [user]);

  if (!profile) return <div>Loading...</div>;

  return (
    <div className={`profile-page ${theme}`}>
      <div className="profile-header">
        <img src={user.photoURL} alt="Profile" className="avatar" />
        <h1>{profile.name}</h1>
      </div>
      <div className="profile-grid">
        <ProfileCard icon={<FaUser />} title="Username" value={profile.name} theme={theme} />
        <ProfileCard icon={<FaEnvelope />} title="Email" value={profile.email} theme={theme} />
        <ProfileCard icon={<FaRocket />} title="Aura" value={`${profile.aura}/160`} theme={theme} />
        <ProfileCard icon={<FaHistory />} title="Member Since" value="April 2023" theme={theme} />
      </div>
      <div className="saved-roadmaps">
        <h2>My Roadmaps</h2>
        <div className="roadmaps-grid">
          {savedRoadmaps.map((roadmap, index) => (
            <motion.div
              key={roadmap.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <RoadmapCard roadmap={roadmap} theme={theme} onView={setSelectedRoadmap} />
            </motion.div>
          ))}
        </div>
      </div>

      <AnimatePresence>
        {selectedRoadmap && (
          <motion.div 
            className="modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div 
              className="roadmap-modal"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
            >
              <button className="close-button" onClick={() => setSelectedRoadmap(null)}>
                <FaTimes />
              </button>
              <h2>{selectedRoadmap.query}</h2>
              <p>Skill Level: {selectedRoadmap.skillLevel}</p>
              {selectedRoadmap.steps.map((step, index) => (
                <div key={index} className="roadmap-step">
                  <div className="step-header">
                    <div className="step-icon">
                      {index % 4 === 0 && <FaRocket />}
                      {index % 4 === 1 && <FaBook />}
                      {index % 4 === 2 && <FaLightbulb />}
                      {index % 4 === 3 && <FaGraduationCap />}
                    </div>
                    <h3>{step.title}</h3>
                  </div>
                  <ReactMarkdown>{step.description}</ReactMarkdown>
                </div>
              ))}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <style jsx>{`
        .profile-page {
          padding: 80px 20px;
          background-color: var(--background-color);
          color: var(--text-color);
          min-height: 100vh;
        }
        .profile-header {
          text-align: center;
          margin-bottom: 3rem;
        }
        .avatar {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          margin-bottom: 1rem;
        }
        h1 {
          font-size: 2.5rem;
          color: var(--primary-color);
        }
        .profile-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          gap: 2rem;
          max-width: 1000px;
          margin: 0 auto 3rem;
        }
        .saved-roadmaps {
          max-width: 1000px;
          margin: 0 auto;
        }
        .saved-roadmaps h2 {
          font-size: 2rem;
          color: var(--primary-color);
          margin-bottom: 1.5rem;
        }
        .roadmaps-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          gap: 1.5rem;
        }
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.7);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .roadmap-modal {
          background-color: var(--card-background);
          padding: 2.5rem;
          border-radius: 15px;
          max-width: 800px;
          width: 90%;
          max-height: 90vh;
          overflow-y: auto;
          position: relative;
        }
        .close-button {
          position: absolute;
          top: 10px;
          right: 10px;
          background: none;
          border: none;
          font-size: 1.5rem;
          cursor: pointer;
          color: var(--text-color);
        }
        .roadmap-step {
          margin-bottom: 2rem;
        }
        .step-header {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
        }
        .step-icon {
          background-color: var(--primary-color);
          color: #fff;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.2rem;
          margin-right: 1rem;
        }
        @media (max-width: 768px) {
          .profile-grid {
            grid-template-columns: 1fr;
          }
          .roadmaps-grid {
            grid-template-columns: 1fr;
          }
        }
      `}</style>
    </div>
  );
};

export default Profile;
