import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from '../firebaseConfig';
import { FaGoogle } from 'react-icons/fa';
import { useAuth } from '../AuthContext';

const SignUp = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const { theme } = useAuth();

  const handleGoogleSignUp = async () => {
    try {
      await signInWithPopup(auth, new GoogleAuthProvider());
      navigate('/playground');
    } catch (error) {
      setError('Error signing up with Google. Please try again.');
      console.error('Error signing up with Google', error);
    }
  };

  const handleEmailSignUp = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      navigate('/playground');
    } catch (error) {
      setError('Error signing up. Please try again.');
      console.error('Error signing up with email', error);
    }
  };

  return (
    <div className={`signup-page ${theme}`}>
      <div className="signup-container">
        <div className="signup-form">
          <h1>Create an account</h1>
          <p>Join Masterfy and start your learning journey</p>
          <button className="social-button google" onClick={handleGoogleSignUp}>
            <FaGoogle /> Sign up with Google
          </button>
          <div className="divider">or</div>
          <form onSubmit={handleEmailSignUp}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            {error && <p className="error-message">{error}</p>}
            <button type="submit" className="signup-button">Sign Up</button>
          </form>
          <p className="login-link">
            Already have an account? <a href="/login">Log in</a>
          </p>
        </div>
        <div className="signup-sidebar">
          <div className="feature-list">
            <h2>Why join Masterfy?</h2>
            <ul>
              <li>Personalized learning paths</li>
              <li>Access to expert-curated content</li>
              <li>Track your progress and achievements</li>
              <li>Connect with a community of learners</li>
            </ul>
          </div>
        </div>
      </div>
      <style jsx>{`
        .signup-page {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 100vh;
          background-color: var(--background-color);
          color: var(--text-color);
        }
        .signup-container {
          display: flex;
          width: 900px;
          background-color: var(--card-background);
          border-radius: 10px;
          overflow: hidden;
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
        }
        .signup-form {
          flex: 1;
          padding: 3rem;
        }
        h1 {
          font-size: 2.5rem;
          margin-bottom: 0.5rem;
          color: var(--primary-color);
        }
        p {
          margin-bottom: 2rem;
          color: var(--text-color);
        }
        .social-button {
          width: 100%;
          padding: 0.8rem;
          margin-bottom: 1rem;
          border: none;
          border-radius: 5px;
          font-size: 1rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease;
        }
        .social-button svg {
          margin-right: 0.5rem;
        }
        .google {
          background-color: #4285f4;
          color: white;
        }
        .google:hover {
          background-color: #3367d6;
          transform: translateY(-2px);
          box-shadow: 0 5px 15px rgba(66, 133, 244, 0.3);
        }
        .divider {
          text-align: center;
          margin: 1rem 0;
          color: var(--text-color);
        }
        .divider::before,
        .divider::after {
          content: '';
          display: inline-block;
          width: 40%;
          height: 1px;
          background-color: var(--text-color);
          margin: 0 0.5rem;
          vertical-align: middle;
        }
        form {
          display: flex;
          flex-direction: column;
        }
        input {
          padding: 0.8rem;
          margin-bottom: 1rem;
          border: 1px solid var(--text-color);
          border-radius: 5px;
          font-size: 1rem;
          background-color: var(--background-color);
          color: var(--text-color);
        }
        .error-message {
          color: #ff6b6b;
          margin-bottom: 1rem;
        }
        .signup-button {
          padding: 0.8rem;
          background-color: var(--primary-color);
          color: white;
          border: none;
          border-radius: 5px;
          font-size: 1rem;
          cursor: pointer;
          transition: all 0.3s ease;
        }
        .signup-button:hover {
          background-color: ${theme === 'light' ? '#5a4cad' : '#7a6add'};
          transform: translateY(-2px);
          box-shadow: 0 5px 15px rgba(106, 90, 205, 0.3);
        }
        .login-link {
          margin-top: 1rem;
          text-align: center;
        }
        .login-link a {
          color: var(--primary-color);
          text-decoration: none;
          font-weight: bold;
        }
        .signup-sidebar {
          flex: 1;
          background-color: var(--primary-color);
          padding: 3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
        }
        .feature-list h2 {
          font-size: 1.8rem;
          margin-bottom: 1.5rem;
        }
        .feature-list ul {
          list-style-type: none;
          padding: 0;
        }
        .feature-list li {
          margin-bottom: 1rem;
          font-size: 1.1rem;
        }
        @media (max-width: 768px) {
          .signup-container {
            flex-direction: column;
            width: 100%;
            height: 100%;
            border-radius: 0;
          }
          .signup-sidebar {
            display: none;
          }
        }
      `}</style>
    </div>
  );
};

export default SignUp;