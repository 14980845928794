import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { BackgroundGradient } from '../components/BackgroundGradient';
import { BentoGrid, BentoGridItem } from '../components/BentoGrid';
import axios from 'axios';
import { FaPlay, FaSpinner } from 'react-icons/fa';

const VideosContainer = styled.div`
  padding: 20px;
  color: #ffffff;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  background: linear-gradient(to right, #00ccb1, #7b61ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const DashboardVideos = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchVideos = useCallback(async (topic) => {
    if (!topic) {
      setError('No topic selected. Please go back to the dashboard and select a topic.');
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post('https://masterfy.onrender.com/api/chat', {
        message: `Generate 5 video ideas related to ${topic.title}. For each video, provide a title and a brief description. Format the response as a JSON array with objects containing 'title' and 'description' properties.`
      });

      const videoData = JSON.parse(response.data.response);
      setVideos(videoData.map((video, index) => ({
        ...video,
        id: index + 1,
        thumbnail: `https://picsum.photos/seed/${index}/300/200`
      })));
    } catch (error) {
      setError('Error fetching videos. Please try again.');
      console.error('Error fetching videos:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (location.state?.topic) {
      fetchVideos(location.state.topic);
    } else {
      setError('No topic selected. Please go back to the dashboard and select a topic.');
      setIsLoading(false);
    }
  }, [location.state, fetchVideos]);

  if (isLoading) {
    return (
      <VideosContainer>
        <FaSpinner className="spinner" />
        <p>Loading videos...</p>
      </VideosContainer>
    );
  }

  if (error) {
    return (
      <VideosContainer>
        <p>{error}</p>
        <button onClick={() => navigate('/dashboard')}>Back to Dashboard</button>
      </VideosContainer>
    );
  }

  return (
    <VideosContainer>
      <BackgroundGradient>
        <Title>Videos: {location.state?.topic?.title || 'Loading...'}</Title>
        <BentoGrid>
          <AnimatePresence>
            {videos.map((video) => (
              <BentoGridItem key={video.id}>
                <VideoCard
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <VideoThumbnail style={{ backgroundImage: `url(${video.thumbnail})` }}>
                    <PlayIcon />
                  </VideoThumbnail>
                  <VideoTitle>{video.title}</VideoTitle>
                </VideoCard>
              </BentoGridItem>
            ))}
          </AnimatePresence>
        </BentoGrid>
      </BackgroundGradient>
    </VideosContainer>
  );
};

const VideoCard = styled(motion.div)`
  background-color: #1a1a1a;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
`;

const VideoThumbnail = styled.div`
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  position: relative;
`;

const PlayIcon = styled(FaPlay)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;

  ${VideoCard}:hover & {
    color: #ffffff;
    transform: translate(-50%, -50%) scale(1.1);
  }
`;

const VideoTitle = styled.h3`
  padding: 15px;
  font-size: 1.1rem;
  color: #ffffff;
`;

export default DashboardVideos;