import React, { useEffect, useRef } from 'react';
import { FaChartLine, FaLaptopCode, FaPencilAlt, FaUserGraduate } from 'react-icons/fa';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';

const Homepage = () => {
  const { theme } = useAuth();
  const navigate = useNavigate();
  const row1Ref = useRef(null);
  const row2Ref = useRef(null);
  const row3Ref = useRef(null);

  useEffect(() => {
    const rows = [row1Ref.current, row2Ref.current, row3Ref.current];

    rows.forEach((row, index) => {
      const originalContent = row.innerHTML;
      row.innerHTML = originalContent + originalContent;

      const cards = row.children;
      const totalWidth = cards[0].offsetWidth * cards.length / 2;

      let currentPosition = 0;
      const speed = (index % 2 === 0 ? 1 : -1) * 0.5;

      const animate = () => {
        currentPosition += speed;
        if (currentPosition > totalWidth) {
          currentPosition = 0;
        } else if (currentPosition < -totalWidth) {
          currentPosition = -totalWidth / 2;
        }
        row.style.transform = `translateX(${currentPosition}px)`;
        requestAnimationFrame(animate);
      };

      requestAnimationFrame(animate);
    });
  }, []);

  const creatorsRow1 = [
    {
      name: 'Danny Chen',
      description: 'Founder @QuestTP',
      location: '1.5K | NY, USA',
      image: 'https://media.discordapp.net/attachments/1265407222884733033/1265407250202366155/pfpfpfp_danny.jpg?ex=66a165d6&is=66a01456&hm=615748c22adeb853c58dc2e05e5be81c5be60392cc198c1f5e4fbdb0299e3bcc&=&format=webp&width=462&height=1000'
    },
    {
      name: "Ryan Chen",
      description: 'AI/ML Student',
      location: 'NY, USA',
      image: 'https://xsgames.co/randomusers/avatar.php?g=male'
    },
    {
      name: 'Howard Mandell',
      description: "Blogger",
      location: 'Bangladesh',
      image: 'https://xsgames.co/randomusers/avatar.php?g=female'
    },
  ];

  const creatorsRow2 = [
    {
      name: 'Nimai Garg',
      description: "Founder @Bright Sparks",
      location: 'California, USA',
      image: 'https://xsgames.co/randomusers/avatar.php?g=pixel'
    },
    {
      name: 'Milk Road',
      description: "Student @Stuy HS",
      location: 'Manhattan, NY',
      image: 'https://xsgames.co/randomusers/avatar.php?g=male'
    },
    {
      name: 'Arvid Kahl',
      description: '150k Podcaster',
      location: 'NY, NY',
      image: 'https://xsgames.co/randomusers/avatar.php?g=pixel'
    },
  ];

  const creatorsRow3 = [
    {
      name: 'Alice Johnson',
      description: 'Tech Blogger',
      location: 'SF, USA',
      image: 'https://xsgames.co/randomusers/avatar.php?g=female'
    },
    {
      name: 'Emily Watson',
      description: 'Design Enthusiast',
      location: 'LA, USA',
      image: 'https://xsgames.co/randomusers/avatar.php?g=male'
    },
    {
      name: 'Sophia Brown',
      description: 'Content Creator',
      location: 'Chicago, USA',
      image: 'https://xsgames.co/randomusers/avatar.php?g=pixel'
    },
  ];

  const navigateToPlayground = () => {
    navigate('/playground');
  };

  return (
    <div className={`homepage ${theme}`}>
      <main className="main-content">
        <div className="hero-section">
          <h1 className="main-title">One Search Away From Mastery.</h1>
          <p className="main-description">Master any skill in seconds.</p>
          <div className="cta-buttons">
            <button className="start-button" onClick={navigateToPlayground}>Try now →</button>
            <button className="talk-button" onClick={navigateToPlayground}>Talk to us →</button>
          </div>
          <div className="floating-icons">
            <FaChartLine className="icon chart" />
            <FaLaptopCode className="icon code" />
            <FaPencilAlt className="icon pencil" />
            <FaUserGraduate className="icon graduate" />
          </div>
        </div>

        <div className="trusted-section">
          <p>Trusted by top teams</p>
          <div className="trusted-logos">
            <img src="trusted/Untitled-1.png" alt="Trusted Company 1" />
            <img src="trusted/Untitled-2.png" alt="Trusted Company 2" />
            <img src="trusted/Untitled-3.png" alt="Trusted Company 3" />
            <img src="trusted/Untitled-4.png" alt="Trusted Company 4" />
            <img src="trusted/Untitled-5.png" alt="Trusted Company 5" />
            <img src="trusted/Untitled-6.png" alt="Trusted Company 6" />
          </div>
        </div>

        <div className="divider">
          <span>Empowering students around the world</span>
        </div>

        <section className="creators-section">
          <div className="creators-container">
            <div className="creator-cards row-1" ref={row1Ref}>
              {creatorsRow1.map((creator, index) => (
                <CreatorCard key={index} creator={creator} />
              ))}
            </div>
            <div className="creator-cards row-2" ref={row2Ref}>
              {creatorsRow2.map((creator, index) => (
                <CreatorCard key={index} creator={creator} />
              ))}
            </div>
            <div className="creator-cards row-3" ref={row3Ref}>
              {creatorsRow3.map((creator, index) => (
                <CreatorCard key={index} creator={creator} />
              ))}
            </div>
          </div>
          <button className="video-button" onClick={navigateToPlayground}>
            Try it out in the playground →
          </button>
        </section>
      </main>

      <style jsx>{`
        .homepage {
          font-family: 'Poppins', sans-serif;
          background-color: var(--background-color);
          color: var(--text-color);
          transition: all 0.3s ease;
          min-height: 100vh;
          width: 100%;
        }

        .main-content {
          max-width: 1200px;
          margin: 0 auto;
          padding: 80px 20px;
        }

        .hero-section {
          text-align: center;
          position: relative;
          padding: 60px 0;
        }

        .main-title {
          font-size: 4rem;
          font-weight: 700;
          margin-bottom: 20px;
          line-height: 1.2;
          background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .main-description {
          font-size: 1.5rem;
          color: var(--text-color);
          margin-bottom: 30px;
          max-width: 600px;
          margin-left: auto;
          margin-right: auto;
        }

        .cta-buttons {
          display: flex;
          justify-content: center;
          gap: 20px;
          margin-bottom: 50px;
        }

        .start-button, .talk-button {
          padding: 12px 24px;
          border-radius: 30px;
          font-weight: 600;
          font-size: 1rem;
          transition: all 0.3s ease;
          cursor: pointer;
        }

        .start-button {
          background-color: var(--primary-color);
          color: #fff;
          border: none;
        }

        .talk-button {
          background-color: transparent;
          color: var(--primary-color);
          border: 2px solid var(--primary-color);
        }

        .start-button:hover, .talk-button:hover {
          transform: translateY(-3px);
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .floating-icons {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          pointer-events: none;
        }

        .icon {
          position: absolute;
          font-size: 2rem;
          color: var(--primary-color);
          opacity: 0.5;
        }

        .icon.chart { top: 20%; left: 10%; }
        .icon.code { top: 60%; right: 15%; }
        .icon.pencil { bottom: 30%; left: 20%; }
        .icon.graduate { top: 40%; right: 25%; }

        .trusted-section {
          text-align: center;
          margin-bottom: 60px;
        }

        .trusted-section p {
          font-size: 1rem;
          color: var(--text-color);
          margin-bottom: 20px;
        }

        .trusted-logos {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;
          flex-wrap: wrap;
        }

        .trusted-logos img {
          max-width: 80px;
          height: auto;
          filter: ${theme === 'light' ? 'grayscale(100%)' : 'invert(1) grayscale(100%)'};
          opacity: 0.6;
          transition: all 0.3s ease;
        }

        .trusted-logos img:hover {
          filter: ${theme === 'light' ? 'grayscale(0%)' : 'invert(1) grayscale(0%)'};
          opacity: 1;
        }

        .divider {
          display: flex;
          align-items: center;
          text-align: center;
          margin: 40px 0;
        }

        .divider::before,
        .divider::after {
          content: '';
          flex: 1;
          border-bottom: 1px solid var(--text-color);
        }

        .divider span {
          padding: 0 10px;
          font-size: 1.2rem;
          font-weight: 600;
        }

        .creators-section {
          text-align: center;
          padding: 40px 0;
          background-color: ${theme === 'light' ? 'rgba(255, 255, 255, 0.9)' : 'rgba(42, 42, 42, 0.9)'};
          border-radius: 20px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .creators-container {
          overflow: hidden;
          padding: 20px 0;
        }

        .creator-cards {
          display: flex;
          gap: 20px;
          padding: 10px 0;
        }

        .video-button {
          background-color: var(--secondary-color);
          color: #fff;
          border: none;
          padding: 12px 24px;
          border-radius: 30px;
          font-weight: 600;
          font-size: 1rem;
          transition: all 0.3s ease;
          cursor: pointer;
          margin-top: 30px;
        }

        .video-button:hover {
          transform: translateY(-3px);
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        @media (max-width: 768px) {
          .main-title {
            font-size: 3rem;
          }

          .main-description {
            font-size: 1.2rem;
          }

          .cta-buttons {
            flex-direction: column;
            align-items: center;
          }

          .start-button, .talk-button {
            width: 100%;
            max-width: 300px;
          }

          .creator-cards {
            flex-direction: column;
            align-items: center;
          }

          .creator-card {
            width: 100%;
            max-width: 300px;
          }
        }
      `}</style>
    </div>
  );
};

const CreatorCard = ({ creator }) => (
  <div className="creator-card">
    <div className="creator-image" style={{ backgroundImage: `url(${creator.image})` }}></div>
    <div className="creator-info">
      <h3 className="creator-name">{creator.name}</h3>
      <p className="creator-description">{creator.description}</p>
      <p className="creator-location">{creator.location}</p>
    </div>
    <style jsx>{`
      .creator-card {
        background-color: var(--card-background);
        border-radius: 12px;
        padding: 20px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        display: flex;
        align-items: center;
        min-width: 300px;
        margin: 10px;
      }

      .creator-card:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
      }

      .creator-image {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #f0e6ff;
        background-size: cover;
        background-position: center;
        margin-right: 15px;
        flex-shrink: 0;
      }

      .creator-info {
        flex: 1;
        text-align: left;
      }

      .creator-name {
        font-size: 1.1rem;
        font-weight: 600;
        margin: 0 0 5px;
        color: var(--primary-color);
      }

      .creator-description {
        font-size: 0.9rem;
        color: var(--text-color);
        margin: 0 0 5px;
      }

      .creator-location {
        font-size: 0.8rem;
        color: var (--text-color);
        opacity: 0.7;
        margin: 0;
      }
    `}</style>
  </div>
);

export default Homepage;
