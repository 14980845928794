import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../AuthContext';
import { db, doc, getDoc, updateDoc } from '../firebaseConfig';
import { FaHome, FaBook, FaVideo, FaCrown, FaLightbulb, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { logUserAction } from '../utils/analytics';

const Dashboard = () => {
  const { user, theme } = useAuth();
  const navigate = useNavigate();
  const [aura, setAura] = useState(160);
  const [stats, setStats] = useState({
    skillsSearched: 0,
    blogsRead: 0,
    videosWatched: 0,
    practiceProblems: 0
  });
  const [recentActivity, setRecentActivity] = useState(null);
  const [isMiddleMenuCollapsed, setIsMiddleMenuCollapsed] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [roadmap, setRoadmap] = useState([]);
  const [showTutorial, setShowTutorial] = useState(false);
  const [tutorialStep, setTutorialStep] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setAura(userData.aura);
          setStats(userData.stats || {
            skillsSearched: 0,
            blogsRead: 0,
            videosWatched: 0,
            practiceProblems: 0
          });
          setRecentActivity(userData.recentActivity);
          setShowTutorial(!userData.hasSeenTutorial);

          // Load the most recent search if available
          if (userData.searches && Object.keys(userData.searches).length > 0) {
            const lastSearch = Object.entries(userData.searches).pop();
            if (lastSearch) {
              const [query, data] = lastSearch;
              setSearchQuery(query);
              setRoadmap(data.roadmap || []);
              setSelectedTopic(data.roadmap && data.roadmap.length > 0 ? data.roadmap[0] : null);
            }
          }
        }
      };
      fetchUserData();
    }
  }, [user]);

  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
    logUserAction('topic_click', { topicId: topic.id, searchQuery: searchQuery });
  };

  const handleOptionClick = (option) => {
    if (!selectedTopic) {
      alert('Please select a topic first.');
      return;
    }
    logUserAction('option_click', { option, searchQuery: searchQuery, topicId: selectedTopic.id });
    switch (option) {
      case 'learn':
        navigate('/dashboard/learn', { state: { topic: selectedTopic, searchQuery: searchQuery } });
        break;
      case 'practice':
        navigate('/dashboard/practice', { state: { topic: selectedTopic, searchQuery: searchQuery } });
        break;
      case 'videos':
        navigate('/dashboard/videos', { state: { topic: selectedTopic, searchQuery: searchQuery } });
        break;
      default:
        break;
    }
  };

  const handleTutorialNext = () => {
    if (tutorialStep < 2) {
      setTutorialStep(tutorialStep + 1);
    } else {
      setShowTutorial(false);
      updateDoc(doc(db, 'users', user.uid), { hasSeenTutorial: true });
    }
    logUserAction('tutorial_step', { step: tutorialStep + 1 });
  };

  const renderTutorial = () => {
    const steps = [
      {
        title: 'Welcome to Your Dashboard!',
        content: 'This is where you can track your progress and continue your learning journey.'
      },
      {
        title: 'Aura System',
        content: 'Your daily aura is 160. You replenish 1 aura every 8 minutes. Using features decreases your aura.'
      },
      {
        title: 'Learning Options',
        content: 'Choose to read explanations, solve practice problems, or watch videos to master each topic.'
      }
    ];

    return (
      <motion.div 
        className="tutorial-modal"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0}}
      >
        <h2>{steps[tutorialStep].title}</h2>
        <p>{steps[tutorialStep].content}</p>
        <button onClick={handleTutorialNext}>
          {tutorialStep < 2 ? 'Next' : 'Got it!'}
        </button>
      </motion.div>
    );
  };
    
  if (!user) {
    return <div>Loading...</div>;
  }
    
  return (
    <div className={`dashboard ${theme}`}>
      <div className="sidebar">
        <div className="logo">Masterfy</div>
        <nav>
          <button onClick={() => navigate('/dashboard')}><FaHome /> Dashboard</button>
          <button onClick={() => navigate('/dashboard/practice')}><FaBook /> Practice</button>
          <button onClick={() => navigate('/dashboard/videos')}><FaVideo /> Videos</button>
          <button onClick={() => navigate('/dashboard/pass')}><FaCrown /> Masterfy Pass</button>
        </nav>
        <button className="tutorial-button" onClick={() => setShowTutorial(true)}>
          <FaLightbulb />
        </button>
      </div>
      
      <motion.div 
        className={`middle-menu ${isMiddleMenuCollapsed ? 'collapsed' : ''}`}
        animate={{ width: isMiddleMenuCollapsed ? '60px' : '300px' }}
      >
        <button 
          className="collapse-button"
          onClick={() => setIsMiddleMenuCollapsed(!isMiddleMenuCollapsed)}
        >
          {isMiddleMenuCollapsed ? <FaChevronRight /> : <FaChevronLeft />}
        </button>
        {!isMiddleMenuCollapsed && (
          <>
            <h1>Hello, {user.displayName}</h1>
            <p>Masterfy your skills</p>
            <div className="aura-display">
              <p>Aura: {aura}/160</p>
            </div>
            <div className="stats-grid">
              <div className="stat-box">
                <p>Skills Searched: {stats.skillsSearched}</p>
              </div>
              <div className="stat-box">
                <p>Blogs Read: {stats.blogsRead}</p>
              </div>
              <div className="stat-box">
                <p>Videos Watched: {stats.videosWatched}</p>
              </div>
              <div className="stat-box">
                <p>Practice Problems: {stats.practiceProblems}</p>
              </div>
            </div>
            <div className="activity-section">
              <h2>Recent Activity</h2>
              {recentActivity ? (
                <p>{recentActivity}</p>
              ) : (
                <p>No recent activity</p>
              )}
            </div>
          </>
        )}
      </motion.div>
      
      <div className="main-content">
        {searchQuery && (
          <>
            <h2>Masterfying: {searchQuery}</h2>
            <div className="roadmap-display">
              {roadmap && roadmap.length > 0 ? (
                roadmap.map((step, index) => (
                  <div 
                    key={step.id} 
                    className={`roadmap-node ${step.id === selectedTopic?.id ? 'active' : ''}`}
                    onClick={() => handleTopicClick(step)}
                  >
                    <div className="node"></div>
                    <p>{step.title}</p>
                  </div>
                ))
              ) : (
                <p>No roadmap available for this search query.</p>
              )}
            </div>
            <div className="learning-options">
              <div className="option-box" onClick={() => handleOptionClick('learn')}>
                <FaBook />
                <p>Learn about this topic</p>
              </div>
              <div className="option-box" onClick={() => handleOptionClick('practice')}>
                <FaBook />
                <p>Practice problems</p>
              </div>
              <div className="option-box" onClick={() => handleOptionClick('videos')}>
                <FaVideo />
                <p>Watch videos</p>
              </div>
            </div>
          </>
        )}
        {!searchQuery && (
          <div className="no-search-message">
            <p>You haven't searched for any topics yet. Go search to start your journey!</p>
            <button onClick={() => navigate('/playground')}>Go to Playground</button>
          </div>
        )}
      </div>
    
      <AnimatePresence>
        {showTutorial && renderTutorial()}
      </AnimatePresence>

      <style>{`
        .dashboard {
          display: flex;
          height: 100vh;
          background-color: ${theme === 'light' ? '#f0f4f8' : '#1a202c'};
          color: ${theme === 'light' ? '#2d3748' : '#e2e8f0'};
          font-family: 'Poppins', sans-serif;
        }

        .sidebar {
          width: 200px;
          background-color: ${theme === 'light' ? '#ffffff' : '#2d3748'};
          padding: 20px;
          display: flex;
          flex-direction: column;
          box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
        }

        .logo {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 30px;
          color: #7a6add;
        }

        .sidebar nav {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        .sidebar button {
          background: none;
          border: none;
          color: ${theme === 'light' ? '#4a5568' : '#a0aec0'};
          font-size: 16px;
          text-align: left;
          padding: 10px;
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          transition: all 0.3s ease;
          border-radius: 8px;
        }

        .sidebar button:hover {
          background-color: ${theme === 'light' ? '#e2e8f0' : '#4a5568'};
          color: #7a6add;
        }

        .tutorial-button {
          margin-top: auto;
          background-color: #7a6add !important;
          color: white !important;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
          transition: all 0.3s ease;
        }

        .tutorial-button:hover {
          transform: scale(1.1);
        }

        .middle-menu {
          width: 300px;
          background-color: ${theme === 'light' ? '#ffffff' : '#2d3748'};
          padding: 20px;
          position: relative;
          transition: all 0.3s ease;
          box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
        }

        .middle-menu.collapsed {
          width: 60px;
        }

        .collapse-button {
          position: absolute;
          top: 20px;
          right: -15px;
          background-color: #7a6add;
          color: white;
          border: none;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          z-index: 10;
          transition: all 0.3s ease;
        }

        .collapse-button:hover {
          transform: scale(1.1);
        }

        .aura-display {
          background-color: ${theme === 'light' ? '#e2e8f0' : '#4a5568'};
          padding: 15px;
          border-radius: 12px;
          margin-bottom: 20px;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          color: #7a6add;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .stats-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 15px;
          margin-bottom: 20px;
        }

        .stat-box {
          background-color: ${theme === 'light' ? '#e2e8f0' : '#4a5568'};
          padding: 15px;
          border-radius: 12px;
          text-align: center;
          font-size: 14px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          transition: all 0.3s ease;
        }

        .stat-box:hover {
          transform: translateY(-5px);
        }

        .activity-section {
          background-color: ${theme === 'light' ? '#e2e8f0' : '#4a5568'};
          padding: 15px;
          border-radius: 12px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .main-content {
          flex: 1;
          padding: 30px;
          overflow-y: auto;
        }

        .roadmap-display {
          display: flex;
          justify-content: space-between;
          margin-bottom: 30px;
          background-color: ${theme === 'light' ? '#ffffff' : '#2d3748'};
          padding: 20px;
          border-radius: 12px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .roadmap-node {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          transition: all 0.3s ease;
        }

        .node {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #7a6add;
          margin-bottom: 10px;
          transition: all 0.3s ease;
        }

        .roadmap-node.active .node {
          width: 30px;
          height: 30px;
          box-shadow: 0 0 10px #7a6add;
        }

        .roadmap-node:hover .node {
          transform: scale(1.2);
        }

        .learning-options {
          display: flex;
          gap: 20px;
        }

        .option-box {
          flex: 1;
          background-color: ${theme === 'light' ? '#ffffff' : '#2d3748'};
          padding: 30px;
          border-radius: 12px;
          text-align: center;
          cursor: pointer;
          transition: all 0.3s ease;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .option-box:hover {
          transform: translateY(-10px);
          box-shadow: 0 10px 20px rgba(122, 106, 221, 0.2);
        }

        .option-box svg {
          font-size: 36px;
          color: #7a6add;
          margin-bottom: 15px;
        }

        .option-box p {
          font-size: 18px;
          font-weight: 600;
          color: ${theme === 'light' ? '#2d3748' : '#e2e8f0'};
        }

        .tutorial-modal {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: ${theme === 'light' ? '#ffffff' : '#2d3748'};
          padding: 30px;
          border-radius: 12px;
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
          z-index: 1000;
          max-width: 90%;
          width: 400px;
        }

        .tutorial-modal h2 {
          font-size: 24px;
          color: #7a6add;
          margin-bottom: 15px;
        }

        .tutorial-modal p {
          font-size: 16px;
          line-height: 1.6;
          margin-bottom: 20px;
        }

        .tutorial-modal button {
          background-color: #7a6add;
          color: white;
          border: none;
          padding: 12px 24px;
          border-radius: 8px;
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;
          transition: all 0.3s ease;
        }

        .tutorial-modal button:hover {
          background-color: #6a5acd;
          transform: translateY(-2px);
        }

        @media (max-width: 1200px) {
          .middle-menu {
            width: 250px;
          }

          .stats-grid {
            grid-template-columns: 1fr;
          }
        }

        @media (max-width: 992px) {
          .dashboard {
            flex-direction: column;
          }

          .sidebar {
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;
          }

          .sidebar nav {
            flex-direction: row;
          }

          .middle-menu {
            width: 100%;
          }

          .collapse-button {
            display: none;
          }

          .main-content {
            padding: 20px;
          }
        }

        @media (max-width: 768px) {
          .learning-options {
            flex-direction: column;
          }

          .option-box {
            padding: 20px;
          }

          .roadmap-display {
            flex-wrap: wrap;
            justify-content: center;
          }

          .roadmap-node {
            margin: 10px;
          }
        }

        @media (max-width: 576px) {
          .sidebar {
            flex-direction: column;
            padding: 20px;
          }

          .sidebar nav {
            flex-direction: column;
            width: 100%;
          }

          .tutorial-button {
            margin-top: 20px;
          }

          .stats-grid {
            grid-template-columns: 1fr;
          }
        }

        /* Custom scrollbar */
        ::-webkit-scrollbar {
          width: 8px;
        }

        ::-webkit-scrollbar-track {
          background: ${theme === 'light' ? '#f1f1f1' : '#2d3748'};
        }

        ::-webkit-scrollbar-thumb {
          background: #7a6add;
          border-radius: 4px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #6a5acd;
        }

        /* Animations */
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }

        @keyframes slideIn {
          from { transform: translateY(20px); opacity: 0; }
          to { transform: translateY(0); opacity: 1; }
        }

        .dashboard {
          animation: fadeIn 0.5s ease-out;
        }

        .sidebar, .middle-menu, .main-content {
          animation: slideIn 0.5s ease-out;
        }

        /* Accessibility improvements */
        .sidebar button:focus,
        .option-box:focus,
        .tutorial-button:focus,
        .tutorial-modal button:focus {
          outline: 2px solid #7a6add;
          outline-offset: 2px;
        }

        /* Additional UI enhancements */
        .aura-display {
          position: relative;
          overflow: hidden;
        }

        .aura-display::after {
          content: '';
          position: absolute;
          top: -50%;
          left: -50%;
          width: 200%;
          height: 200%;
          background: linear-gradient(
            to bottom right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.1) 50%,
            rgba(255, 255, 255, 0) 100%
          );
          transform: rotate(45deg);
          animation: shine 3s infinite;
        }

        @keyframes shine {
          0% { transform: translateX(-100%) rotate(45deg); }
          100% { transform: translateX(100%) rotate(45deg); }
        }

        .stat-box {
          position: relative;
          overflow: hidden;
        }

        .stat-box::before {
          content: '';
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.2),
            transparent
          );
          transition: 0.5s;
        }

        .stat-box:hover::before {
          left: 100%;
        }

        .option-box {
          position: relative;
          overflow: hidden;
        }

        .option-box::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(120deg, transparent, rgba(122, 106, 221, 0.1), transparent);
          transform: translateX(-100%);
          transition: 0.6s;
        }

        .option-box:hover::before {
          transform: translateX(100%);
        }
      `}</style>
    </div>
  );
};

export default Dashboard;
